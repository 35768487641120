import ajax from 'axios'
import router from '../router'
//ajax.defaults.baseURL = 'http://localhost:8084/'
ajax.defaults.baseURL = 'http://mh.xalanqi.com:8084/'
ajax.defaults.timeout = 20000 // 超时时间 20s
ajax.defaults.withCredentials = false
ajax.interceptors.request.use(function (config) {
  let token = localStorage.getItem('Authorization') 
  if (token) {
    // 将token放到请求头发送给服务器,将tokenkey放在请求头中
    config.headers['Authorization'] = token
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

ajax.interceptors.response.use(response => {
  if (response.data.code === 401) {
    localStorage.removeItem('Authorization')
    router.push('/').then(r => {})
  }
  return response
})

export default ajax
